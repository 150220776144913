<template>
  <div>
    <myBreadcrumb :nav="nav"></myBreadcrumb>
    <div class="top">
      <img class="left" src="https://picsum.photos/580/385" alt="" />
      <div class="right">
        <div>
          <div class="title">银雀山汉简博物馆</div>
          <div class="item">
            <img src="@/assets/images/Frame-33.png" alt="" />
            <span>占地面积：15892平</span>
          </div>
          <div class="item">
            <img src="@/assets/images/Frame-36.png" alt="" />
            <span>北京市顺义区石园街道石园南大街顺义文化中心</span>
          </div>
          <div class="item">
            <img src="@/assets/images/Frame-34.png" alt="" />
            <span>可容纳人数：300人</span>
          </div>
          <div class="item">
            <img src="@/assets/images/Frame-35.png" alt="" />
            <span>场馆设备：投影仪*1</span>
          </div>
          <div class="detail">
            <span>预约时间：2022-05-16</span>
            <span>预约场次：09:00-12:00</span>
          </div>
        </div>
        <div class="butt">待审核</div>
      </div>
    </div>
    <div class="titles">场馆介绍</div>
    <div class="details">银雀山汉墓位于山东省临沂市市区银雀山西南麓，是全国规模较大的古代墓群之一，以西汉墓葬为主，兼有战国至唐、宋、元、明、清历代的墓葬群。</div>
    <div class="titles">温馨提示</div>
    <div class="details">银雀山汉墓位于山东省临沂市市区银雀山西南麓，是全国规模较大的古代墓群之一，以西汉墓葬为主，兼有战国至唐、宋、元、明、清历代的墓葬群。</div>
  </div>
</template>

<script>
export default {
  name: "OrderDetail",
  data() {
    return {
      nav: [
        {
          title: '首页',
          to: {path: '/'}
        },
        {
          title: "个人中心",
          to: {path: '/my', query: {tabs: '0'}}
        },
        {
          title: "场馆记录",
          to: {path: '/my', query: {tabs: '5'}}
        },
        {
          title: "场馆预约记录",
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped lang="less">
.top {
  display: flex;

  .left {
    width: 580px;
    height: 385px;
    border-radius: 10px;
  }

  .right {
    flex: 1;
    margin-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-weight: 700;
      font-size: 20px;
      color: #333333;
      margin-bottom: 8px;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: 16px;

      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      span {
        font-size: 16px;
        color: #333333;
        flex: none;
        order: 1;
        flex-grow: 0;
      }
    }

    .detail{
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      padding: 16px 132px 16px 16px;
      background: white;
      border: 1px dashed #EDA200;
      width: max-content;

      span{
        font-size: 15px;
        line-height: 22px;
        color: #333333;
      }
      span:last-child{
        margin-top: 12px;
      }
    }

    .butt{
      padding: 12px 110px;
      background: #EDA200;
      box-shadow: 0 2px 4px rgba(14, 79, 71, 0.2);
      border-radius: 4px;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #FFFFFF;
      flex: none;
      order: 0;
      flex-grow: 0;
      margin-top: 37px;
      width:max-content;
    }
  }
}

.titles{
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  color: #333333;
  margin: 24px 0 12px 0;
}
.details{
  font-size: 16px;
  line-height: 23px;
  color: #666666;
}
.details:last-child{
  margin-bottom: 150px;
}
</style>